import React, { Fragment } from "react"
import styles from "./card.module.css"
import { Link } from "gatsby"

import { FiDownload } from "react-icons/fi"

import imageBack from "../images/card/back.svg"

import { withPrefix } from "gatsby"

const Download = props => (
  <div className={styles.download}>
    <h4>Download curriculum</h4>
    <a href={props.to} className={styles.downloadbutton}>
      <FiDownload />
    </a>
  </div>
)

const LinkButton = props => (
  <div className={styles.buttoncontainer}>
    <Link className={styles.button} to={props.to}>
      {props.buttonTitle}
    </Link>
  </div>
)

const FindOutMoreButton = class extends React.Component {
  render() {
    return (
      <div>
        <a
          href="#"
          className={styles.button}
          onClick={this.props.handleShowForm}
        >
          FIND OUT MORE
        </a>
      </div>
    )
  }
}

const Normal = props => (
  <Fragment>
    <div>
      <h1>{props.title}</h1>
      {props.children}
    </div>
    <div className={styles.buttoncontainer}>
      {props.download && (
        <Download
          to={withPrefix(
            "/downloads/Dual Good Health - " + props.title + ".pdf"
          )}
        />
      )}
      {!(props.download || props.enquire) && (
        <LinkButton buttonTitle={props.buttonTitle} to={props.to} />
      )}
      {(props.download || props.enquire) && (
        <FindOutMoreButton handleShowForm={props.handleShowForm} />
      )}
    </div>
  </Fragment>
)

const Form = props => (
  <Fragment>
    <div>
      <h1>{props.title}</h1>
      {!props.formPost && <h2>Enquire now</h2>}
      {props.formPost && <h2>Thanks for your enquiry</h2>}
    </div>
    {!props.formPost && (
      <form
        onSubmit={props.handleSubmitForm}
        name="contact"
        method="post"
        action="/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={props.handleChange} />
          </label>
        </p>

        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          onChange={props.handleChange}
        />
        <input
          type="text"
          id="email"
          name="email"
          placeholder="Email"
          onChange={props.handleChange}
        />
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="Phone"
          onChange={props.handleChange}
        />
        <div style={{ height: "60px" }} />
        <div className={styles.buttoncontainer}>
          <input type="submit" value="Enquire" className={styles.button} />
        </div>
      </form>
    )}
    {/* <a href="#" className={styles.button}>Enquire</a> */}
  </Fragment>
)

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Card = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showForm: false, name: "", email: "", message: "" }
  }

  handleShowForm = e => {
    e.preventDefault()
    this.setState({ showForm: true })
  }

  handleBackButton = e => {
    e.preventDefault()
    this.setState({ showForm: false })
  }

  handleSubmitForm = e => {
    console.log(this.state)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => this.setState({ formPost: true }))
      .catch(error => alert(error))
    e.preventDefault()
  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
      message: this.props.title,
    })

  render() {
    // const { name, email, message } = this.state
    return (
      <Fragment>
        <div>
          <div
            style={{ height: this.props.height || "300px" }}
            className={`${styles.card} ${this.state.showForm && styles.form}`}
          >
            <div className={styles.cardHeader}>
              <div className={styles.backButton}>
                <a href="#" onClick={this.handleBackButton}>
                  <img src={imageBack} alt="" />
                </a>
              </div>
            </div>

            <img src={this.props.cardImage} alt="" />
            {!this.state.showForm && (
              <Normal
                title={this.props.title}
                height={this.props.height}
                children={this.props.children}
                download={this.props.download}
                to={this.props.to}
                buttonTitle={this.props.buttonTitle}
                handleShowForm={this.handleShowForm}
                enquire={this.props.enquire}
              />
            )}
            {this.state.showForm && (
              <Form
                handleChange={this.handleChange}
                handleSubmitForm={this.handleSubmitForm}
                title={this.props.title}
                height={this.props.height}
                formPost={this.state.formPost}
              />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Card
