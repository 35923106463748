import React from "react"
import Layout, { SimpleLayout } from "../components/layout"
import Hero from "../components/hero"
import indexStyles from "./index.module.css"
import styles from "./partner.module.css"

import Card from "../components/card"
import imageHealthcare from "../images/cards/card-cpr.png"
import imageFirstAid from "../images/partner/card-fa.png"
import imageBLS from "../images/cards/card-healthcare.png"

import ThreeCircles from "../components/threecircles"
import imageHeart from "../images/partner/heart.png"
import imageNonprofit from "../images/partner/nonprofit.png"
import imageImmersive from "../images/partner/immersive.png"
//benefits
import imagePay from "../images/partner/pay.svg"
import imageFlexible from "../images/partner/flexible.svg"
import imageSupport from "../images/partner/support.svg"
import imageNocap from "../images/partner/nocap.svg"
import imageBranding from "../images/partner/branding.svg"
import imageMarketing from "../images/partner/marketing.svg"
import Connected from "../components/connected"
import { FooterWrapper } from "../components/footer"

import imageBackground from "../images/partner/partner-background.png"

export default () => (
  <FooterWrapper>
    <Layout image={imageBackground}>
      <Hero
        hero="Partner & re-sell"
        herosub="Adding immersive training to your courses is easy. Read on to see how you can partner with us"
      />
      <div
        style={{
          marginTop: `80px`,
          marginBottom: `120px`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a className={indexStyles.button} href="#signup">
          SEE OPTIONS
        </a>
      </div>
    </Layout>
    <div className={styles.partner}>
      <HowItWorks style={{ padding: "0 50px" }} />
      {/* <WhoIsThisFor style={{ background: "white" }} /> */}
      <ThreeCircles
        title="Who is this for?"
        circle1={{ image: imageHeart, text: "Skill training organisations " }}
        circle2={{
          image: imageNonprofit,
          text: "Nonprofit & community",
        }}
        circle3={{
          image: imageImmersive,
          text: "Immersive hardware suppliers",
        }}
      >
        <p>
          This option is for training organisations that supply training to
          third parties in the private and public sector. This includes private
          training companies, non-profit community programs, public awareness
          campaigns and more. Wondering if this offer is suitable for your
          organisation? <a href="mailto:hello@dualgoodhealth.com">Contact us</a>
          to find out more.
        </p>
      </ThreeCircles>
      <Benefits
        style={{
          padding: "60px 50px",
          paddingBottom: "40px",
          background: "white",
        }}
      />
      <div style={{ paddingBottom: "20px", background: "white" }} id="signup" />
      <Signup
        style={{
          padding: "50px 20px",
          backgroundImage:
            "linear-gradient(0.25turn,rgba(99, 69, 148, 1),rgba(97, 169, 254, 1))",
        }}
      />
      <SimpleLayout style={{ background: "white" }}>
        <Connected />
      </SimpleLayout>
    </div>
  </FooterWrapper>
)

const Signup = props => {
  let cardHeight = "500px"
  return (
    <SimpleLayout style={props.style}>
      <div className={styles.signup}>
        <h1>Sign up</h1>
        <h2>
          To sign up as a partner and pay for licenses to resell please click
          below
        </h2>
      </div>
      <div className={styles.signupcards}>
        <div className="card-feed">
          <Card
            title="CPR"
            buttonTitle="SIGN UP"
            cardImage={imageHealthcare}
            height={cardHeight}
            enquire={true}
          >
            <h2>Software credits</h2>
            <h3>Packages of 100, 200 and 500 credits</h3>
            <h4>
              Various options available. All credits packages can be used within
              12 calendar months.
            </h4>
          </Card>
          <Card
            title="First Aid"
            buttonTitle="SIGN UP"
            cardImage={imageFirstAid}
            height={cardHeight}
            enquire={true}
          >
            <h2>Software credits</h2>
            <h3>Packages of 100, 200 and 500 credits</h3>
            <h4>
              Various options available. All credits packages can be used within
              12 calendar months.
            </h4>
          </Card>
          <Card
            title="Basic Life Support"
            buttonTitle="SIGN UP"
            cardImage={imageBLS}
            height={cardHeight}
            enquire={true}
          >
            <h2>Software credits</h2>
            <h3>Packages of 100, 200 and 500 credits</h3>
            <h4>
              Various options available. All credits packages can be used within
              12 calendar months.
            </h4>
          </Card>
        </div>
      </div>
    </SimpleLayout>
  )
}

const Benefits = props => (
  <SimpleLayout style={props.style}>
    <div className={styles.benefits}>
      <div className={styles.circleflex}>
        <div className={styles.circle}>
          <img src={imagePay} alt="pay" />
        </div>
        <h1>Pay-as-you-train packages</h1>
      </div>

      <div className={styles.circleflex}>
        <div className={styles.circle}>
          <img src={imageFlexible} alt="flexible" />
        </div>
        <h1>Flexible pricing model</h1>
      </div>

      <div className={styles.circleflex}>
        <div className={styles.circle}>
          <img src={imageSupport} alt="support" />
        </div>
        <h1>Software support included</h1>
      </div>

      <div className={styles.circleflex}>
        <div className={styles.circle}>
          <img src={imageNocap} alt="no cap" />
        </div>
        <h1>No cap on margins</h1>
      </div>

      <div className={styles.circleflex}>
        <div className={styles.circle}>
          <img src={imageBranding} alt="brandin" />
        </div>
        <h1>Branding options available</h1>
      </div>

      <div className={styles.circleflex}>
        <div className={styles.circle}>
          <img src={imageMarketing} alt="marketing" />
        </div>
        <h1>Marketing materials available</h1>
      </div>
    </div>
  </SimpleLayout>
)

const HowItWorks = props => (
  <SimpleLayout style={props.style}>
    <div className={styles.howitworksbox}>
      <div className={styles.flexcontainer}>
        <h1 div style={{ marginBottom: `30px` }}>
          How it works
        </h1>
        <p>
          If you require our out-of-the-box solutions to re-sell and/or conduct
          private training courses, we have provided an opporunity to purchase
          credits in bulk, at a discounted rate. This way you can add immersive
          training to your existing course and a new stream of revenue to your
          training business with our VR courses.
        </p>

        <h2>Easy step-by-step process</h2>

        <div className={styles.circlecontainer}>
          <div className={styles.circle}>
            <div className={styles.circleflex}>
              <h1>01.</h1>
              <h2>SIGN UP & BUY</h2>
              <p>re-seller credits at a reduced fee</p>
            </div>
          </div>

          <div className={styles.circle}>
            <div className={styles.circleflex}>
              <h1>02.</h1>
              <h2>INSTALL</h2>
              <p>hardware & software</p>
            </div>
          </div>

          <div className={styles.circle}>
            <div className={styles.circleflex}>
              <h1>03.</h1>
              <h2>TRAIN with VR</h2>
              <p>add VR training to your courses</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SimpleLayout>
)
