import React from "react"
import { SimpleLayout } from "./layout"
import styles from "./threecircles.module.css"

export default props => (
  <div>
    <SimpleLayout style={{ background: "#F8F7FE", padding: "60px 0" }}>
      <div className={styles.threecircles}>
        <h1>{props.title}</h1>
        <div className={styles.children}>{props.children}</div>
        <div className={styles.circles}>
          <div className={styles.circlecontainer}>
            <div className={styles.circle}>
              <img src={props.circle1.image} alt="" />
            </div>
            <h2 dangerouslySetInnerHTML={{ __html: props.circle1.text }} />
            <p>{props.circle1.subtext}</p>
          </div>

          <div className={styles.circlecontainer}>
            <div className={styles.circle}>
              <img src={props.circle2.image} alt="" />
            </div>
            <h2 dangerouslySetInnerHTML={{ __html: props.circle2.text }} />
            <p>{props.circle2.subtext}</p>
          </div>

          <div className={styles.circlecontainer}>
            <div className={styles.circle}>
              <img src={props.circle3.image} alt="" />
            </div>
            <h2 dangerouslySetInnerHTML={{ __html: props.circle3.text }} />
            <p>{props.circle3.subtext}</p>
          </div>
        </div>
      </div>
    </SimpleLayout>
  </div>
)
